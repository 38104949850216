/*eslint-disable */
// import Clk_Engine_TrackerModel from '../../Model/Clk_Engine_Tracker'
import request from '../../../Utils/curl'

let clkEngineTrackers = {
  /**
   * clk_engine_trackerList
   */
  async clk_engine_trackerList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "clk_engine_tracker_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at clk_engine_trackerList() and Exception:',err.message)
    }
  },

  /**
   * clk_engine_trackerView
   */
  async clk_engine_trackerView (context, id) {
    try {      
      let post_data = new FormData();
      post_data.append('id', id);
      return await request.curl(context, "clk_engine_tracker_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at clk_engine_trackerView() and Exception:",err.message)
    }    
  },

  /**
   * clk_engine_trackerAdd
   */
  async clk_engine_trackerAdd (context, clk_engine_trackerObj) {
    try{
      let post_data = new FormData();
    
      for (let key in clk_engine_trackerObj) {
        post_data.append(key, clk_engine_trackerObj[key]);
      }

      return await request.curl(context, "clk_engine_tracker_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at clk_engine_trackerAdd() and Exception:',err.message)
    }
  },

  /**
   * clk_engine_trackerEdit
   */
  async clk_engine_trackerEdit (context, clk_engine_trackerObj) {
    try{
      let post_data = new FormData();
    
      for (let key in clk_engine_trackerObj) {
        post_data.append(key, clk_engine_trackerObj[key]);
      }

      return await request.curl(context, "clk_engine_tracker_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at clk_engine_trackerEdit() and Exception:',err.message)
    }
  },

  /**
   * clk_engine_trackerDelete
   */
  async clk_engine_trackerDelete (context, id) {
    try{
      let post_data = new FormData();
      
      post_data.append('id', id);

      return await request.curl(context, "clk_engine_tracker_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at clk_engine_trackerDelete() and Exception:',err.message)
    }
  }
}

export {
  clkEngineTrackers
}
